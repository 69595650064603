@import "../../../style/colors";

.Checkout {
  .formSection {
    .sectionHeader {
      display: flex;
      border-bottom: 1px solid $violet;
      justify-content: space-between;
      padding-bottom: 0.5em;
      margin-bottom: 1em;

      h4 {
        margin: 0;
      }
    }
  }

  .formSection.location {
    .locationAndMap {
      display: flex;
      > div {
        width: 50%;
      }

      .mapContainer {
        min-height: 200px;
      }
    }
  }

  .formSection.bestTimesToSwurf {
    .day {
      margin: 0.5em 0;
      display: flex;
      .label {
        width: 7em;
      }
      .times {
        display: flex;

        *:after {
          content: ",";
        }
        .time {
          margin-left: 0.25em;
          color: $tangerine;
        }
        :first-child {
          margin: 0;
        }
        :last-child:after {
          content: "";
        }
      }
    }
  }

  .formSection.space {
    .field_facilities {
      .fieldInput {
        display: flex;

        * {
          margin-left: 0.25em;
        }
        *:after {
          content: ",";
        }
        :first-child {
          margin: 0;
        }
        :last-child:after {
          content: "";
        }
      }
    }
  }

  .formSection.promotions {
    .promotionsList {
      display: flex;
      flex-wrap: wrap;
    }

  }

  .formSection.subscription {
    .planContainer {
      display: flex;
    }
  }

  .formSection.paymentMethod {

  }

  .formSection.terms {
    .termsHelp {
      margin-top: 0.5em;
    }
  }
}

@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .Checkout {
      .formSection.subscription {
        .planContainer {
          display: block;
        }
      }
    }
  }
}
