@import "../../style/colors";

.Navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $lavendar;

    .logo {
        h1 {
            margin: 0 0 1em;
        }
        a {
            display: block;
            padding: 30px 70px 25px 30px;
        }

        a:hover {
            background: $lavendarDark;
        }

        img {
            width: 150px;
        }
    }

    ul.menu {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-self: stretch;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: flex;

            button {
                background: transparent;
                cursor: pointer;
                text-align: left;
            }
            a, button {
                font-family: raisonne-extrabold-pro, sans-serif;
                display: block;
                flex-grow: 1;
                padding: 10px 0 10px 23px;
                color: $lavendarDark;
                font-size: 25px;
                border: 0 $lavendar solid;
                border-left-width: 7px;
                text-decoration: none;
            }

            a.active,
            a:hover,
            button.active,
            button:hover {
                color: $violet;
                border-color: $violet;
            }
            a:hover,
            button:hover {
                background-color: $lavendarDark;
            }
            a.active:hover,
            button.active:hover {
                color: $purpleBlack;
                border-color: $purpleBlack;
            }
        }
        .logout {
            margin-top: auto;
            margin-bottom: 1em;
            a {
                background: url("../../assets/lavendarDarkBack.png") 23px center no-repeat;
                padding-left: 60px;
                padding-top: 14px;
            }
            a:hover {
                /* TODO need violet back asset */
                background-image: $lavendarDark url("../../assets/violetBack.png");
                background-color: transparent;
            }
        }
    }
}
