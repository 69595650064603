.asset-card-main {
    background-color: #CEC0F9;
    padding: 15px;
    border-radius: 12px;
    max-width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    &.promotionPrompt {
        max-width: 100%;

        .fieldInput {
            &.imageContainer {
                width: 100%;
            }
        }

        .asset-card {
            .asset-card-inner {
                .bar {
                    width: 100%;
                }
            }

            .asset-card-inner-popup {
                .bar {
                    width: 100%;
                }
            }
        }
    }

    .asset-card {
        position: relative;
        .loader-container{
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 12px;
            overflow: hidden;
        }
        .asset-card-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .bar {
                background-color: #fff;
                border: 1px solid #545454;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                min-width: 260px;
            }

            .uploadBox {
                position: relative;

                .upload {
                    width: 50px;
                    height: 40px;
                    border: none;
                    overflow: hidden;
                    background-color: #e7e7e7;
                    border-radius: 8px;

                }

                label {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    input {
                        max-width: 100%;
                        width: 50px;
                        height: 40px;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                    }
                }
            }

            .title {
                .field {
                    margin-bottom: 0;
                }

                .input-container {
                    input {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        padding: 10px;
                    }
                }
            }

            .deleteAsset {
                img {
                    width: 30px;
                    height: auto;
                    max-width: 100%;
                }
            }
        }

        .asset-card-inner-popup {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .bar {
                background-color: #fff;
                border: 1px solid #545454;
                border-radius: 10px;
                padding: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                min-width: 260px;
            }

            .uploadBox {
                position: relative;

                .upload {
                    width: 50px;
                    height: 40px;
                    border: none;
                    overflow: hidden;
                    background-color: #e7e7e7;
                    border-radius: 8px;

                }

                label {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    input {
                        max-width: 100%;
                        width: 50px;
                        height: 40px;
                        position: absolute;
                        z-index: 1;
                        cursor: pointer;
                    }
                }
            }

            .title {
                .field {
                    margin-bottom: 0;

                    .fieldError {
                        margin-top: 0.5em;
                        padding-left: 10px;
                    }
                }

                .input-container {
                    input {
                        border: none;
                        box-shadow: none;
                        outline: none;
                        padding: 10px;
                    }

                }
            }

            .deleteAsset {
                img {
                    width: 30px;
                    height: auto;
                    max-width: 100%;
                }
            }
        }
    }

    .pageControls {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            background-color: #9766d5;
            margin: 0;
            width: 100% !important;
        }



    }

    .blank-area {
        min-width: 330px;
    }
}
.ReactModal__Content.asset-image-upload{
    border-radius: 12px;
}

.form .field{
    &.pageControls{
        gap: 15px;
        padding-top: 24px;
        >div{
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
            button{
                margin: 0 !important;
                width: auto;
                min-width: fit-content;
                padding: 15px 28px;
            }
        }
    }
}


@media (max-width: 768px){

}

@media(max-width: 600px) {
    .form{
        .field.pageControls{
            padding-top: 0;
            > div{
                padding: 0;
            }
        }
    }
    .Page10Promotion {
        .field {
            .fieldInput {
                .input-container {
                    display: flex;
                    flex-direction: column;

                    input {
                        width: 100%;
                        max-width: calc(100% - 50px);
                    }
                }
            }
        }
    }

    .asset-card-main {
        max-width: 100%;

        .imageContainer {
            width: 100%;
        }

        .asset-card {
            .asset-card-inner {
                width: 100%;

                .bar {
                    min-width: auto;
                    width: 100%;
                    max-width: calc(100% - 50px);
                }
            }
        }

        &.promotionPrompt {
            .asset-card {
                .asset-card-inner-popup {
                    .bar {
                        min-width: auto;

                        .title {
                            .input-container {
                                input {
                                    width: 100px;
                                    padding:  5px 8px 5px 0;
                                }
                            }
                            .field{
                                .fieldError{
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .ReactModal__Content.asset-image-upload{
        width: 320px;
        max-width: calc(100% - 30px);
    }
}