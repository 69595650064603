@import "../../style/colors";

.Main {
  display: flex;
  flex-grow: 1;
  color: $violet;

  a {
    color: $violet;
  }

  .NavBar {

  }

  .contentContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .contentArea {
      flex-grow: 1;
      overflow: auto;

      .content {
        padding: 2em;
      }
    }
  }

  .Content {
    flex-grow: 1;
  }
}

@media only screen and (max-width: 600px) {
  .Main {
    .Navbar {
      z-index: 1000;
      position: fixed;
      height: 100vh;
      width: 100%;
      margin: 0;
      transition: transform 0.3s ease-in-out;
      .logo {
        a {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .closeButton {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        margin-top: 32px;
        margin-right: 20px;
        background: url("../../assets/close.svg") center no-repeat;
      }

      ul.menu {
        li {
          a {
            padding-right: 30px;
          }
        }
      }
    }

    .Navbar.closed {
      transform: translateX(-100%);
    }

    .contentContainer {
      flex-grow: 1;
      .contentArea {
        .content {
          padding: 0;
        }
      }
    }
  }
}