@import "../../../style/colors";

.SwurfTimes {
  .restrictedContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 1em;

    button {
      margin-right: 10%;
      margin-bottom: 2em;
      border: 0;
      border-radius: 5px;
      padding: 5px 1em;
      background-color: $lavendar;
      color: $violet;
      cursor: pointer;
    }

    button:hover {
      background-color: $lightLavendar;
      color: $lightViolet;
    }
  }

  .week {
    display: flex;

    .day {
      display: flex;
      flex-direction: column;
      width: 12%;
      padding: 0 2px;

      .dayName {
        text-align: center;
        padding-bottom: 0.5em;
        cursor: pointer;
      }

      .hour {
        background: $peach;
        height: 30px;
        margin: 2px 0;
        cursor: pointer;
      }

      .dayName:hover {
        font-weight: bold;
      }

      .dayName:hover ~ .hour,
      .hour:hover,
      .hour.hover {
        background-color: #f3d5b9;
      }

      .hour.selected {
        background-color: $tangerine;
        background-image: linear-gradient(to bottom right, transparent calc(50% - 1px), $darkTangerine, transparent calc(50% + 1px)), linear-gradient(to bottom left, transparent calc(50% - 1px), $darkTangerine, transparent calc(50% + 1px));
      }

      .dayName:hover ~ .hour.selected,
      .hour.selected:hover,
      .hour.selected.hover {
        background-color: $lightTangerine;
      }
    }

    .dayLabels {
      display: flex;
      flex-direction: column;

      width: 4%;

      .dayName {
        padding-bottom: 0.5em;
        cursor: pointer;
      }

      .hourName {
        font-family: Verdana, sans-serif;
        text-align: right;
        background: none;
        padding-right: 10px;
        height: 30px;
        margin: 2px 0;
        cursor: pointer;
      }

      .hourName:hover {
        font-weight: bold;
      }
    }
  }

  .weekHelpText {
    margin-top: 0.5em;
    margin-left: 4%;
    padding-left: 2px;
  }
}


@media only screen and (max-width: 600px) {
  .weekContainer {
    min-width: 300px;
    margin: 0 -10px;
    overflow: scroll;

    .week {
      .dayLabels {
        min-width: 40px;
        font-size: 80%;
        .hourName {

        }
      }
      .day {
        min-width: 30px;
        .fullDayName {
          display: none;
        }
      }
    }
  }
}
