@import "../../style/colors";

.HostListItem {
  background: $peach;
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 10px;
  display: flex;

  .image {
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 70px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  .image.noImage {
    background: $lavendar;
    font-size: 50px;
  }

  .details {
    flex-grow: 1;
    .header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .headerLeft {
        display: flex;

        .name {
          font-weight: bold;
          font-size: 30px;
        }

        .status {
          margin-left: 30px;
          background: $lavendar;
          padding: 6px 15px;
          text-decoration: none;
          border-radius: 15px;
          margin-bottom: 4px;
        }

        .status.PAYMENT_INCOMPLETE {
          background-color: $tangerine;
          color: white;
          font-weight: normal;
        }

        .status.NEW {
          background-color: $darkPeach;
          color: $violet;
        }

        .status.PAYMENT_ERROR {
          background-color: $tangerine;
          color: white;
        }

      }
      .editButton {
      }

    }
    .address {
      margin-top: 10px;
    }
    .promotions {
      display: flex;
      margin: 15px 0 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .HostListItem {
    flex-direction: column;
    padding: 1em;

    .image {
      display: none;
    }
    .details {
      .header {
        flex-direction: column;
        align-items: stretch;

        .headerLeft {
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 1em;

          .name {
            font-size: 120%;
          }
          .status {
            margin-left: 1em;
            text-align: center;
          }
        }

        .editButton {
          text-align: center;
        }
      }
      .address {
        display: none;
      }
    }
  }
}
