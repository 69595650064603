@import "../../../style/colors";

.CreateUpdateHost {
  .formSection {
    margin-bottom: 1em;

  }
  .withDescription {
    margin-bottom: 0.5em;
  }
  .withDescription + .pageDescription {
    margin-bottom: 1em;
    color: grey;
  }

  .pageControls {
    display: flex;
    justify-content: space-between;
    .cancel {
      background: #ccc;
    }
  }
}


@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .pageControls {
      > div {
        display: flex;
        align-items: stretch;
        margin-top: 1em;
        button {
          flex-grow: 1;
          margin-left: 1em;
        }
        button:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
