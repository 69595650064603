@import "colors";

fieldset {
  border: 0;
  padding: 0;
}

.loader.default {
  border-top-color: $lavendar;
}

.hidden {
  display: none;
}

button.button,
a.button {
  background: $darkPeach;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 15px;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

a.button,
a.button:visited {
  color: $violet;
}

.button:hover {
  background-color: $mediumPeach;
}

button.button {
  border-width: 0;
  cursor: pointer;
}

button.button:focus {
  outline: 0;
}
