@import "../../../style/colors";

.CreateUpdateHost {
  .Page1Initial {
    .field_name {
      flex-grow: 1;

      .fieldInput {
        display: flex;

        input {
          flex-grow: 1;
          font-size: 30px;
        }
      }
    }

    .mainSection {
      display: flex;
      flex-wrap: wrap;


      .imageFieldSet {
        flex-grow: 1;
        flex-basis: 300px;
        margin-right: 2em;

        .fieldLabel {
          display: none;
        }

        .groupImage {
          background-color: $lavendar;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 10px;
          // gap: 10px;
          padding: 10px;
          .imageContainer {
            height: 100px;
            width: calc(33.33% - 10px);
            padding-bottom: 0;
            position: relative;
            margin-bottom: 10px;
        
            .image {
              box-shadow: 3px 4px 7px 0 #0000004d;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 10px;
              border: 1px solid black;
            
              background-size: cover;
              background-position: center;
              display: flex;
              align-items: stretch;
              justify-content: stretch;

              .loader-container .background {
                border-radius: 10px;
              }

              label {
                color: $violet;
                // background: white;
                border-radius: 10px;
                padding: 7px;
                font-size: 14px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: auto;
                opacity: 0;
                visibility: hidden;
                transition: .2s ease;
                span{
                  background-color: #CEC0F9;
                  padding: 3px 6px;
                  border-radius: 4px;
                }
              }

              label.remove {
                position: absolute;
                top: 0;
                left: auto;
                right: 0;
                font-size: 12px;
                z-index: 1;
              }

              input {
                display: none;
              }
            }
            &:hover{
              .image{
                label{
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
            &.addImage{
              .image{
                label{
                  opacity: 1;
                  visibility: visible;
                  width: 100%;
                  height: 100%;
                  padding: 0;
                }
              }
            }
          }
        }

        .helpText {
          margin-top: 1em;
        }
      }

      .detailsFieldSet {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 300px;

        .field_description,
        .field_description label {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }

        .field_description .fieldInput {
          flex-grow: 1;
          display: flex;
          align-items: stretch;
        }

        .field_email {}

        .fieldInput {
          display: flex;

          input[type=text],
          textarea {
            flex-grow: 1;
          }
        }

      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .Page1Initial {
      .field_name {
        padding-top: 1em;

        .fieldInput {
          input {
            font-size: inherit;
          }
        }
      }

      .mainSection {
        .imageFieldSet {
          margin-right: 0;

          .fieldLabel {
            display: block;
          }


          .helpText {
            margin-bottom: 2em;
          }
        }

        .detailsFieldSet {
          textarea {
            min-height: 20em;
          }
        }
      }
    }
  }
}