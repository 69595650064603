@import "../../style/colors";

.LoginRegister {
  display: flex;
  flex-grow: 1;
  align-items: stretch;

  a {
    color: $violet;
  }

  .backToSwurfCo {
    text-decoration: none;
    padding: 12px 15px 10px 40px;
    background: $lavendar url("../../assets/lavendarBack.png") no-repeat 9px center;
    border-radius: 15px;
    position: absolute;
    left: 40px;
    top: 40px;
  }

  .image {
    width: 50%;
    background: url("./loginRegisterImage.png") center no-repeat;
    background-size: cover;

  }

  .form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    color: $violet;
    background: $formBackground;
    overflow: auto;

    .header {
      display: flex;
      justify-content: center;
      align-self: stretch;
      align-items: center;
      margin-top: 1em;

      .dummy, .backToSwurfCo {
        width: 40px;
      }

      .dummy {
        margin-right: auto;
        margin-left: 40px;
      }

      .logo {
        width: 150px;
      }

      .backToSwurfCo {
        margin-left: auto;
        margin-right: 40px;
        position: static;
        padding: 0;
        height: 33px;
        background: $lavendar url("../../assets/lavendarClose.png") center no-repeat;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-family: raisonne-extrabold-pro, sans-serif;
        font-size: 45px;
        line-height: 55px;
        text-align: center;
        margin: 0;
      }

      .authNav {
        max-width: 260px;
        margin: 0.5em 0 2em;
        text-align: center;

        a {
          font-weight: bold;
        }
      }
      .helpText,
      .fieldError {
        width: 260px;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $lavendar inset !important;
      }

      button {
        background: white;
        color: $violet;
        margin-right: 0;
      }

    }

    .footer {
      margin-bottom: 1em;
    }
  }
}

@media only screen and (max-width: 600px) {
  .LoginRegister {
    .image,
    > .backToSwurfCo {
      display: none;
    }
    .form {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .header {
        .dummy {
          margin-left: 0;
        }
        .backToSwurfCo {
          margin-right: 0;
        }
      }
      .footer {
        text-align: center;
      }
    }
  }
}
