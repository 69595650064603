@import "../../../style/colors";

.CreateUpdateHost {
  .Page4Swurfer {
    .swurferFieldSet {
      max-width: 800px;
      textarea {
        resize:none;
        border-radius: 5px;
        padding: 15px;
        width: 75% !important;
        background: transparent;
        border: 1px solid $violet;
        color: $violet;
        font-size: 16px;
      }
      .CheckboxList {
        .fieldInput {
          flex-wrap: wrap;
          
          label {
            flex-basis: 33%;
          }

        }
      }

      .helpText {
        margin-bottom: 30px;
      }

    }
  }
}