@import "../../../style/colors";

.CreateUpdateHost {
  .Page6Promotion {
    .termsIntro {
      margin-bottom: 0.5em;
    }
    .defaultTerms {
      color: grey;
      padding-top: 0.5em;
      margin-left: 0.5em;
      padding-left: 1em;
      border-left: 1px solid grey;
      font-size: 0.9em;
      max-width: 800px;
    }
  }
}

.ReactModal__Content {
  /* override js set style on element */
  background: $lavendar !important;
  position: unset !important;
  inset: unset !important;
  border: 0 !important;

  color: $violet;
  width: 400px;
  height: auto;
  margin: 4em auto;

  .promotionPrompt {
    .buttons {
      display: flex;

      button {
        width: 50%;
        border: 0;
        background-color: $violet;
        color: white;
        border-radius: 5px;
        font-family: raisonne, sans-serif;
        font-weight: bold;
        padding: 1em 0;
        cursor: pointer;
      }

      button:nth-child(1) {
        margin-right: 0.5em;
      }

      button:nth-child(2) {
        margin-left: 0.5em;
      }

      button:hover {
        background-color: $lightViolet;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .ReactModal__Content {
    width: 230px;

    .promotionPrompt {
      .buttons {
        flex-direction: column;

        button {
          width: initial;
        }
        button:nth-child(1) {
          margin-right: 0;
          margin-bottom: 1em;
        }

        button:nth-child(2) {
          margin-left: 0;
        }
      }
    }
  }
}
