@import "../../../style/colors";

.CreateUpdateHost {
  .Page8Payment {
    .formSection {
      display: flex;
      flex-wrap: wrap;

      .coversFieldSet {

        padding-right: 2em;
      }

      .coversFieldSet,
      .planContainer {
        flex-grow: 1;
        flex-basis: 300px;
      }

      .planContainer {
        margin-bottom: 2em;

        .Plan {
          margin-bottom: 1em;
        }
     
        .field {
          display: flex;
          justify-content: center;
        }
        .invalidPromotionCode {
          background: #ffc9c9;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .Page8Payment {
      .formSection {
        .coversFieldSet {
          padding-right: 0;
        }

        .planContainer {
          .field {
            button:first-child {
              margin-bottom: 1em;
            }
          }
        }
      }
    }
  }
}
