.promotion {
  border-radius: 10px;
  margin-right: 20px;
  padding: 10px 10px 10px 60px;
  background: #fff url("../assets/promotion.svg") 10px 10px no-repeat;
  background-size: 40px;
  span {
    display: block;
  }
  .title {
    font-style: italic;
  }
  .code {
    font-weight: bold;
    font-size: 20px;
    margin: 5px 0;
  }
}
