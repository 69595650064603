.PaymentSuccess,
.PaymentError,
.PaymentCancelled {

  .button {
    margin-right: 1em;
  }
}
@media only screen and (max-width: 600px) {
  .PaymentSuccess,
  .PaymentError,
  .PaymentCancelled {
    margin-top: 1em;
    padding: 0 20px;

    display: flex;

    .button {
      display: block;
      text-align: center;
      margin-bottom: 1em;
    }
    .bottom:last-child {
      margin-bottom: 0;
    }
  }
}