@import "fonts/raisonne-regular-pro/stylesheet.css";
@import "fonts/raisonne-bold-pro/stylesheet.css";
@import "fonts/raisonne-extrabold-pro/stylesheet.css";
@import "style/elements.scss";
@import "style/promotions.scss";

body {
  margin: 0;
  font-family: raisonne, sans-serif;
  font-feature-settings: 'ss02','ss03','ss08','ss12','ss13','ss14','ss15','ss16';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
    height: 100%;
}

#root {
  display: flex;
  height: 100%;
  align-items: stretch;
}
