@import "../../../style/colors";

.CreateUpdateHost {
  .Page7Reservation {
    select {
      border: 1px solid $violet;
      border-radius: 5px;
      padding: 15px;
      font-family: raisonne, sans-serif;
      color: $violet;
      font-size: 20px;
      background-color: transparent;
    }
    .currentLink {
      //margin: 0 0 1em;
      a {
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .Page7Reservation {

    }
  }
}
