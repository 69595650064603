.PayPal {
  .content {
    display: flex;
    justify-content: space-around;

    .planContainer {
      display: flex;
      flex-direction: column;

      .Plan {
        margin-bottom: 1em;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .PayPal {
      margin: 20px 0;
  }
  .content {
    flex-direction: column;
  }
}
