.Dashboard {
  .dashboardHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .businessTitle {
      flex-grow: 1;

      h3 {
        font-size: 65px;
        margin: 20px 0 10px;
      }

      .businessSubtitle {
        margin-bottom: 30px;
        font-weight: bold;
      }
    }
    .createHost {
      margin-bottom: 30px;
    }
  }
  .createFirstHost {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Dashboard {
    .dashboardContent {
      padding: 20px;

      .dashboardHeader {
        justify-content: flex-end;

        .businessTitle {
          display: none;
        }
      }
    }
  }

  .Dashboard.noHosts {
    .dashboardHeader {
      display: none;
    }
  }
}
