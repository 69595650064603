@import "../style/colors";

.form {
  .helpText {
    font-weight: normal;
    margin-top: 0.2em;
    display: block;
    color: grey;
    font-size: 80%;
  }

  .field {
    margin-bottom: 1em;

    .fieldLabel {
      display: block;
      margin-bottom: 0.5em;
    }

    .fieldInput {
      display: block;

      input,
      textarea {
        border-radius: 5px;
        font-family: raisonne, sans-serif;
        font-size: 20px;
        padding: 15px;
        width: 260px;
        background: transparent;
        border: 1px solid $violet;
        color: $violet;
        resize: none;
      }

      .input-container {
        position: relative;
        display: inline-block;
      }

      .input-container img {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        /* Adjust the size as needed */
        height: auto;

      }

      .input-container input {
        // padding-left: 30px;
        padding-left: 10px;
        padding-right: 45px;
        /* Adjust the padding based on the image size */
      }

      input[type=checkbox],
      input[type=radio] {
        width: auto;
      }
    }

    button {
      border: none;
      background: $lavendar;
      color: $violet;
      cursor: pointer;
      font-weight: bold;
      width: 294px;
      border-radius: 5px;
      font-family: raisonne, sans-serif;
      font-size: 20px;
      padding: 15px;
      margin-right: 20px;
    }

    button:hover {
      background-color: $lightLavendar;
    }

    .fieldError {
      color: $tangerine;
      margin-top: 0.5em;
    }

  }

  .error-message {
    color: $tangerine;
    margin-bottom: 1em;
  }

  .field.required .labelText {
    font-weight: bold;
  }

  .CheckboxList,
  .Radio {
    .fieldInput {
      display: flex;

      label {
        margin-bottom: 1em;
        position: relative;

        input {
          position: absolute;
          opacity: 0;
        }

        .label {
          min-height: 30px;
          display: flex;
          position: relative;
          align-items: center;
          opacity: 0.7;
          margin-right: 2em;
          padding-left: 40px;

          .labelContent {
            .description {
              font-size: 0.9em;
              color: grey;
              margin-top: 0.25em;
            }
          }
        }

        .label:hover {
          opacity: 0.9;
        }

        .label:before {
          position: absolute;
          left: 0;
          content: "";
          display: block;
          width: 30px;
          height: 30px;
          background: white;
        }

        input:checked~.label {
          opacity: 1;
        }
      }
    }
  }

  .CheckboxList .fieldInput label {
    .label:before {
      border-radius: 10px;
      background-position: center;
      background-repeat: no-repeat;
    }

    input:checked~.label:before {
      background-image: url("tick.png");
    }
  }

  .Radio .fieldInput label {
    .label:before {
      border-radius: 50%;
    }

    input:checked~.label:before {
      background-color: $violet;
    }

  }
}


@media only screen and (max-width: 600px) {
  .form {
    padding-left: 20px;
    padding-right: 20px;

    .field {
      align-self: stretch;
      display: flex;
      flex-direction: column;

      .fieldInput {
        display: flex;
        flex-direction: column;

        input,
        textarea {
          width: initial;
        }
        .input-container{
          img{
            right: 0;
          }
        }
      }

      button {
        width: initial;
        margin-right: 0;
      }

      button:hover {
        background-color: white;
      }
    }
  }
}