@import "../../style/colors";

.ContentNavBar {
  display: flex;
  align-items: center;
  padding: 2em;
  border-bottom: 1px solid $violet;

  .title {
    flex-grow: 1;

    h2 {
      margin: 0;
    }
    .subtitle {
      margin-top: 0.5em;
    }
  }

  .userInfo {
    a {
    }
  }
}

@media only screen and (max-width: 600px) {
  .ContentNavBar {
    padding: 1.5em 1em 1em;
    position: relative;

    .hamburgerButton {
      position: absolute;
      align-self: flex-start;
      width: 30px;
      height: 30px;
      background: url(../../assets/hamburger.svg) no-repeat;
    }

    .title {
      margin-left: 40px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .userInfo {
      display: none;
    }
  }
}
