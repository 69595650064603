@import "../../../style/colors";

.Page4Times {
  h3 {
    margin-bottom: 0;
  }

  .openingTimes {
    margin: 1em 0 2em;
    .day {
      margin-bottom: 0.5em;
      label {
        display: flex;
        align-items: center;
        .fieldLabel {
          width: 7em;
        }

        select {
          border: 1px solid $violet;
          border-radius: 5px;
          padding: 5px;
          font-family: raisonne, sans-serif;
          background-color: transparent;
        }
        select.hidden {
          display: none;
        }
      }
    }
  }
}
