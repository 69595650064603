@import "../../style/colors";

.Plan {
  background-color: $peach;
  padding: 20px;
  border-radius: 20px;

  h3 {
    margin-top: 0;
  }
}
