@import "../../../style/colors";

.CreateUpdateHost {
  .Page3Contact {
    .contactFieldSet {
      h3 {
        margin-bottom: 0;
      }

      > .helpText {
        margin-bottom: 1em;
      }
    }
  }
}
