$tangerine: #FF5F26;
$darkTangerine: #ec531b;
$lightTangerine: #ff7c4b;
$peach: #FADFC4;
$mediumPeach: #ffbaa0;
$darkPeach: #fea683;
$purpleBlack: #1C1526;
$violet: #471e51;
$lightViolet: #5c3468;
$lavendar: #CEC0F9;
$lightLavendar: #d8cbff;
$lavendarDark: #ad90d0;
$formBackground: #fceee1;
$formLozenge: $darkPeach;
