@import "../../../style/colors";

.CreateUpdateHost {
  .Page2Location {
    .addressSection {
      > div {
        min-height: 50vh;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        .addressFieldSet {
          flex-grow: 1;
          flex-basis: 300px;
          margin-right: 2em;

          display: flex;
          flex-direction: column;

          .search {
            flex-grow: 1;
            position: relative;
            .manualAddressToggle {
              margin: 1em 0;
            }
          }

          .manual {
            .manualAddressToggle {
              margin-bottom: 1em;
            }

            .fieldInput {
              display: flex;

              input {
                flex-grow: 1;
              }
            }

            .field_latitude,
            .field_longitude {
              display: none;
            }
          }
        }

        .map {
          flex-grow: 1;
          flex-basis: 300px;

          display: flex;
          flex-direction: column;

          .fieldError {
            color: $tangerine;
            margin-bottom: 1em;
          }

          .mapContainer {
            flex-grow: 1;
            position: relative;

            .mapOverlayContainer {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #cec0f9dd;
              .mapOverlay {
                color: white;
              }
            }
          }

          .changeLocationHint {
            margin-bottom: 1em;
          }
        }

        .addressFieldSet.addressManual {
          .search {
            display: none;
          }
        }
        .addressFieldSet:not(.addressManual) {
          .manual {
            display: none;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .CreateUpdateHost {
    .Page2Location {
      .addressSection {
        > div {
          .addressFieldSet {
            margin-right: 0;
          }
        }
      }
    }

    .Page2Location.addressSet.editAddress {
      .pageControls,
      .map {
        display: none;
      }
    }
  }
}
